import React, { useState, useEffect } from 'react';
const TermsOfUse = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div className="menuCloseJS closeMenuWrapper">
                <div className="privacyTermsFlex privacyTermsColor">
                    <div className="privacyTermsWidth">
                        <h1 className="privacyTermsHeading">Terms of Use</h1>
                        <div className="privacyTermsWrapper">



                        <div className="privacyTermsContent">
                <p>This document is an electronic record in terms of the information technology act, 2000 and rules there under as applicable and various other statutes. 
                  This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                  <p>This is a legal and binding agreement between you, the user ("user" or "you") of the services, 
                    as defined below, and Project-Forty-Six stating the terms that govern your use of the site (as defined below) and by accepting these terms of use in any manner
                     or accessing this website, you consent, agree and undertake to abide, be bound by and adhere to the terms of use and the privacy policy accessible and any
                      other terms which govern the site (together referred to as "terms of use" or "terms" or "terms and conditions")
                     and if you do not agree to these terms of use, you are not entitled to avail of / use the services and any use thereafter shall be unauthorized.</p>
                     <h1 className="TermsOfuseHeadings" >Description of service and acceptance of terms of use</h1>
                     <p>Project-Forty-Sixing: an adrenaline-fueled sport that requires snowmobilers to adjust their riding styles on the fly to conquer extreme weather conditions.</p>
             <p>Project-Forty-Six content and the partner content will be made available to you through different modes, 
              at the discretion of Project-Forty-Six, including via video on demand basis for viewing at the user's discretion at 
              a time chosen by you and download for offline viewing by you. please note that the availability of, and your ability to access,
               the content or some part of services,</p>
               <ul>
                <li>(a) is subject to Project-Forty-Six's sole discretion and</li>
                <li>(b) may be dependent upon your geographical location and</li>
                <li>(c) is on the condition that not all content or services will be available to all viewers.

                   on account of the nature of the internet, this site and the services may also be accessed in various geographical locations; 
                   and you hereby agree and acknowledge that you are accessing this site and availing of the services, at your own risk, choice and 
                   initiative and you agree and undertake to ensure that your use of the site and the services complies with all applicable laws including
                    the local laws in your jurisdiction. further, such services and content may vary from place to place, time to time and device to device and
                     would be subject to various parameters such as specifications, device, internet availability and speed, bandwidth, etc. you agree and acknowledge 
                     that Project-Forty-Six may monitor or use certain technologies for monitoring of activities including logging of your ip address to ascertain and verify 
                     your geographical location. To facilitate your viewership and access,
                   the services can be packaged by Project-Forty-Six through different models such as, content or services may be accessible</li>
                <li>(a) free of charge which may include advertisements or commercials or</li>
                <li>(b) via subscription through payment of a subscription fee or</li>
                <li>(c) a pay-per-view model with or without advertisements/commercials or</li>
                <li>(c) a pay-per-view model with or without advertisements/commercials or</li>
               </ul>
               <p>The service can be availed through platforms, software and devices, which Project-Forty-Six approves from time to time,
                 the current list for which is as set out below ("compatible system"). in some cases, whether a device is (or remains) a
                  compatible system may depend on software or platform provided or maintained by the device manufacturer or other third parties.
                 As a result, devices that are compatible system at one time may cease to be compatible system in the future.</p>

                 <p>The Project-Forty-Six website works best with:</p>
                 <ul>
                  <li>latest version of google chrome</li>
                  <li>latest version of firefox</li>
                  <li>latest version of safari</li>
                  <li>microsoft windows xp or above / mac os 10.2 or above</li>
                  <li>latest version of adobe flash player</li>
                  <li>javascript and cookies enabled</li>
                  </ul>
                  <p>The Project-Forty-Six applications work best with a minimum system requirement of:</p>
                  <ul>
                    <li>android v.6.0</li>
                    <li>ios 8</li>
                    <li>roku 5.2</li>
                  </ul>

                  <p>Subject to compatibility, you may be able to access the services through mobile phones, 
                    tablets and other ip based connected devices or any compatible system offered by a partner authorized by Project-Forty-Six ("partner").</p>

                    <h1 className="TermsOfuseHeadings">Use of the services is subject to compliance with these terms of use.</h1>

                    <p>Unless otherwise specified, the services is available for individuals who have attained the age of
                       majority in their jurisdiction; in the case of india, this service is restricted to users who are aged 18 years or older.
                        If you are under the relevant age of majority in your jurisdiction,
                       you may only access the site and the services only in accordance with applicable law which enables a minor in your jurisdiction to access such a service.</p>

                       <p>Some content offered on the site may not be suitable for some viewers and therefore viewer discretion is advised.
                         Also, some content offered on the site may not be appropriate for viewership by children. parents and/or legal guardians are advised to exercise
                          discretion before allowing their children and/or wards to access content on this website.
                         Your access to and use of the services is subject to these terms and conditions, privacy policy and all applicable laws, rules, and regulations.</p>
                         <p>Participation in any activity on the site conducted by Project-Forty-Six or viewing of certain content may be subject to 
                          further terms and conditions as may be notified by Project-Forty-Six from time to time on the site or otherwise.</p>


                          <h1 className="TermsOfuseHeadings">Electronic communications</h1>

                          <p>When you use / avail the services or send any data, information or communication to Project-Forty-Six, you agree and understand that
                             you are communicating with Project-Forty-Six through electronic media/ records and you consent to receive communications via electronic records from Outdoor
                              Max periodically and as and when required. Project-Forty-Six will communicate with you by email or any push or other message or electronic records on the email address
                             and or mobile number available with Project-Forty-Six or made available to Project-Forty-Six through a partner which will be deemed adequate service of notice / electronic record.</p>

                             <p>You acknowledge that specifically consent to Project-Forty-Six or our respective affiliates and partners contacting you using the 
                              contact information you have provided to us at

                               any time during your association with us for any purpose including the following purposes:</p>

                               <ul>
                                <li>to obtain feedback regarding the service;</li>
                                <li>to contact you for offering new products or services, whether offered by us, or our respective affiliates or partners.</li>
                                <p>You acknowledge and agree that you may be contacted for the above purposes, using any of the contact details provided by you,
                                   including via emails, text messages or phone calls.</p>
                                   <p>In the event you have indicated any 'do not disturb' preferences to your telecom service provider, and registered yourself on the national customer
                                     preference register / 
                                    'do not disturb' or similar list, we request you to ensure that your registered preferences allow for communications to be sent to you in accordance
                                     with the above provisions.</p>
                                     <p>The content contained in the services including but not limited to on the site 
                                      is protected by copyright, trademark, patent, trade secret and other laws, and shall not be used
                                       except as provided in these terms and conditions, without the written permission of Project-Forty-Six.</p>


                               </ul>

                               <h1 className="TermsOfuseHeadings">Other terms and conditions</h1>
                               <h1 className="TermsOfuseHeadings">1. Intellectual property</h1>
                               <p>The intellectual property rights ("intellectual property rights") in Project-Forty-Six app, underlying company and the company platform, 
                                and material published on Project-Forty-Six app, including (but not limited to) games, contests, software, advertisements, written content,
                                 photographs, graphics, images, illustrations, marks, logos, audio or video clippings and flash animation, is owned by company, its partners, licensors and/or associates.
                                  Users may not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works of, distribute, publicly
                                 perform, publicly display, or in any way exploit any of the materials or content on company either in whole or in part without express written license from company</p>
                                 <h1 className="TermsOfuseHeadings">2. User conduct</h1>
                                 <ul>
                                   <li>1. participant agree not
                                   to disrupt, overburden, or aid or assist in the disruption or overburdening of any computer or server used to 
                                   offer or support company or the company services.</li>
                                   <li>2. participant shall not attempt to gain unauthorised access to the user accounts, servers or networks
                                     connected to company services by any means other than the user interface provided by company, including but not limited to, by circumventing or modifying, 
                                     attempting to circumvent or modify, 
                                    or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that underlies or is part of company services.</li>
                                    <li>3. participant shall not
                                       attempt to transmit content regarding services, products, surveys, contests, pyramid schemes, spam, unsolicited advertising or promotional materials, or chain letters.</li>
                                   <li>You agree that Project-Forty-Six and/or its affiliates or licensors owns and/or retains all rights to the services and the Project-Forty-Six content throughout
                                     the territory of the world in perpetuity. you further agree that the Project-Forty-Six content you access and view as part of the services is owned or controlled
                                      by Project-Forty-Six, its affiliates and or its licensors.
                                     The services and the Project-Forty-Six content are protected by copyright, trademark, design, patent, trade secret, and other intellectual property laws.</li>
                                     <li>except as provided in these terms (or otherwise expressly provided by Project-Forty-Six ), you may not copy, download, stream capture, reproduce,
                                       duplicate, archive, upload, modify, translate, publish, broadcast, transmit, retransmit, distribute,
                                       perform, display, sell, frame or deep-link, make available, or otherwise use any content contained in the services. you further agree not to use the services to:</li>
                                     <li>post, upload or otherwise transmit or link to content that is: unlawful; threatening; abusive; obscene; vulgar; sexually explicit; pornographic or inclusive of nudity; offensive;
                                       excessively violent; invasive of another's privacy, publicity, contract or other rights; tortious; false or misleading; defamatory; libelous; hateful; or discriminatory;</li>
                                     <li>harass or harm another person;</li>
                                     <li>exploit or endanger a minor;</li>
                                     <li>impersonate or attempt to impersonate any person or entity;</li>
                                     <li>violate any patent, trademark, trade secret, copyright, privacy, publicity or other proprietary right;</li>
                                     <li>introduce or engage in activity that involves the use of viruses, software disabling codes, bots, worms, or any other computer code, files or
                                       programs that interrupt, destroy, or limit the functionality
                                       of any computer software or hardware or telecommunications equipment, or otherwise permit the unauthorized use of or access to a computer or a computer network;</li>
                                     <li>attempt to decipher, decompile, disassemble or reverse
                                       engineer any of the software, applications and/or any element comprising the site, the application for accessing the content, or the Project-Forty-Six services;</li>
                                     <li>interfere with, damage, disable,
                                       disrupt, impair, create an undue burden on, or gain unauthorized access to the Project-Forty-Six services, including Project-Forty-Six servers, networks or accounts;</li>
                                     <li>cover, remove, disable, manipulate, block or obscure advertisements or other portions of the Project-Forty-Six services or the content;</li>
                                     <li>delete or revise any information provided by or pertaining to any other user of the Project-Forty-Six services.</li>
                                     <li>use technology or any automated system such as scripts, spiders, offline readers or bots in order to collect or disseminate usernames, passwords,
                                       email addresses or other data from the Project-Forty-Six services, or to circumvent, 
                                      delete, deactivate, decompile, reverse engineer, disassemble or modify any security technology or software that is part of the Project-Forty-Six services or the site;</li>
                                     <li>send or cause to send (directly or indirectly) unsolicited bulk messages or other unsolicited bulk communications of any kind through the Project-Forty-Six services.
                                       If you do so, you acknowledge you will have caused substantial harm to Project-Forty-Six , but that the amount of harm would be extremely difficult to measure.
                                        As a reasonable estimation of such harm, and by way of liquidated damages and not as a penalty, you agree to pay company USD 2000 
                                      for each actual or intended recipient of such communication without prejudice to any other rights of action that Project-Forty-Six may have against you in law or equity;</li>
                                     <li>solicit, collect or request any personal information for commercial or unlawful purposes;</li>
                                     <li>post, upload or otherwise transmit an image or video of another person without that person's consent;</li>
                                     <li>engage in commercial activity (including but not limited to advertisements or solicitations of business; sales; contests; sweepstakes; creating, recreating, 
                                      distributing or advertising an index of any significant portion of the content; or building a business using the content) without Project-Forty-Six prior written consent;</li>
                                     <li>use technology or other means to access, index, frame, or link to the site (including the content) that is not authorized by Project-Forty-Six ;</li>
                                     <li>access the site/application/service (including the content) through any automated means, including "robots," "spiders," or "offline readers"
                                       (other than by individually performed searches on publicly accessible search engines for the sole purpose of, and solely to the extent necessary for, 
                                       creating publicly available search indices – 
                                      but not caches or archives of the site and excluding those search engines or indices that host, promote, or link primarily to infringing or unauthorized content);</li>
                                     <li>use the services to advertise or promote competing services;</li>
                                     <li>use the services in a manner inconsistent with any and all applicable law;</li>
                                     <li>attempt, facilitate, induce, aid and abet, or encourage others to do any of the foregoing.</li>
                                     <li>you are responsible for the security and proper use of your unique user id and password that you enter while registering on the site or on
                                       the partner's website or application and must take all necessary steps to ensure that they are kept confidential, used properly,
                                        and not disclosed to unauthorized people.</li>
                                     <li>you agree that your use of the services and the content may give you the opportunity to view, publish, transmit, submit posts and/or
                                       comment in accordance with these terms and conditions. you agree that you will not misuse the content and/or other user material you access 
                                       and view as part of the service, including without limitation, download/store the content by illegal/non-permitted means, or infringe any of Project-Forty-Six / or its
                                        licensor's / or third party's copyright, trademark design, patent, and other intellectual property including any rights of Project-Forty-Six or its licensors or any
                                         third party subsisting in the content. you further agree that you will not copy/record/edit/modify any of the content you access and view as part of the service, for
                                       any purpose, and/or copy/record/edit/modify any content including video, images etc., by using any means including software, spyware, etc., for any purpose whatsoever.</li>
                                     <li>you further agree that you shall not post, publish, transfer, upload, transmit or submit any of the content, including without limitation videos,
                                       images, comments, user material (defined below) or articles, of the site on any other website, webpage or software, whatsoever.</li>
                                     <li>you agree that the content provided on the site is strictly for your private viewing only and not for public exhibition irrespective of whether
                                       the public is charged for such exhibition or not. you hereby agree to refrain
                                       from further broadcasting or making available the content provided on the site to any other person(s), or in any manner communicate such content to the public.</li>
            
                                   
                                   
                                   </ul>
                                   <p>In case the site allows you to download or stream any of the content therein, you agree that you will not use, sell, transfer
                                     or transmit the content to any person or upload the content on any other website, webpage or software, which may violate/cause
                                     damages or injuries to any rights of Project-Forty-Six or its affiliates or any third party including privacy rights, publicity rights, 
                                     and intellectual property rights.</p>
                                     <p>You may encounter third party applications while using the site including, without limitation, websites, widgets, software, services that interact with the site. 
                                      Your use of these third party applications shall be subject to such third party terms of use or license terms. 
                                      Project-Forty-Six shall not be liable for any representations or warranties or obligations made by such third party applications to you under contract or law.</p>
                                      <h1 className="TermsOfuseHeadings">Termination of service</h1>
                                      <p>Project-Forty-Six reserves the right to immediately terminate, suspend, limit, or restrict your account or your use of the services or 
                                        access to content at any time, without notice or liability, if Project-Forty-Six so determines in its sole discretion, for any reason whatsoever, 
                                        including that you have breached these terms and conditions, the privacy policy, violated any law, rule, or regulation, engaged in any inappropriate conduct, 
                                        provided false or inaccurate information, or for any other reason. You hereby agree and consent to the above and agree and acknowledge that Project-Forty-Six can, 
                                        at its sole discretion, exercise its right in relation to any or all of the above, and that Project-Forty-Six, its directors, officers, employees, affiliates, agents, 
                                        contractors, principals or licensors shall not be liable in any manner for the same; and you hereby agree, acknowledge and consent to the same.</p>
                                        <h1 className="TermsOfuseHeadings">User reviews, comments and other material</h1>
                                        <p>You may have an opportunity to publish, transmit, submit, or otherwise post (collectively, "post") only reviews or comments (collectively, "user material").
                                           As it concerns user material, without prejudice to your obligation to otherwise comply with applicable laws during the course of using the services,
                                           you agree to hereby comply with any and all applicable laws, as well as any other rules and restrictions that may be set forth herein or on the site or services.</p>
                                           <p>You agree that Project-Forty-Six shall have the right but have no obligation, to monitor user material and to restrict or remove user material 
                                            that Project-Forty-Six may determine, in its sole discretion, is inappropriate or for any other reason. you acknowledge that Project-Forty-Six reserves the 
                                            right to investigate and take appropriate legal action against anyone who, in Project-Forty-Six sole discretion, violates these terms, including, but not limited to, 
                                            terminating their account, terminate, suspend, limit, or use of the services or access to content and/or reporting such user material, conduct, or activity, to 
                                            law enforcement authorities, in addition to any other available remedies under law or equity. in addition to the restrictions set forth above,
                                             you must adhere to the below terms and conditions and not host, display, upload, modify, publish, transmit, update or share any information /material/user
                                              material that:</p>
                                              <ul>
                                                <li>(a) belongs to another person and to which you do not have any right to;</li>
                                                <li>(b) is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful, 
                                                  or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever;</li>
                                                <li>(c) harms minors in any way;</li>
                                                <li>(d) infringes any patent, trademark, design, copyright or other proprietary rights;</li>
                                                <li>(e) violates any law for the time being in force;</li>
                                                <li>(f) deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
                                                <li>(g) impersonates another person;</li>
                                                <li>(h) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</li>
                                                <li>(i) threatens the unity, integrity, defense, security or sovereignty of india, friendly relations with foreign states, or public order or causes incitement
                                                   to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation. you also represent
                                                   and warrant that the posting of your user material does not violate any right of any party, including privacy rights, publicity rights, and intellectual property rights.</li>
                       
                                              </ul>

                                              <p>You acknowledge that the user material is not confidential and that you have no expectation of privacy in it.</p>
                                              <p>In no event does Project-Forty-Six assume any responsibility or liability whatsoever for any user material, and you agree
                                                 to waive any legal or equitable rights or remedies you may have against Project-Forty-Six with respect to such user material.</p>
                                                 <p>This site may contain articles contributed by several individuals. the views are exclusively their own and do not represent the views of Project-Forty-Six ,
                                                   affiliates and/ or its management. all liability in respect of the
                                                   above is excluded to the extent permitted by law including any implied terms. indian law and jurisdiction applies with respect to contents of this site.</p>
                                                   <p>If you have any complaints relating to any third party user material or content uploaded on the site, then please contact: ott@discovermediaworks.com your email,
                                                     which must bear your digital signature or a signed written complaint, should include the following information:</p>
                                                     <ul>
                                                      <li>details of the objectionable user material or content;</li>
                                                      <li>details of the basis of the objection;</li>
                                                      <li>what relief is being requested;</li>
                                                      <li>all other relevant information required by Project-Forty-Six to assess the complaint.</li>
                                                     </ul>
                                                     <p>Project-Forty-Six shall on examination of the complaint take all reasonable measures, 
                                                      including taking down the objectionable user material expeditiously in accordance with applicable law. 
                                                      You agree and acknowledge that Project-Forty-Six responsibility in relation to objectionable user material is limited to removing them post receipt of your 
                                                      objections in accordance with these terms of use and Project-Forty-Six shall not be responsible or liable for any other damages or losses incurred by you 
                                                      of any nature whatsoever.</p>
                                                      <h1 className="TermsOfuseHeadings">Linked destinations</h1>
                                                      <p>Part of this site/service contains links to third party sites and third party content. 
                                                        You agree and acknowledge that Project-Forty-Six does not endorse or sponsor such third party sites, content, 
                                                        advertising or other material on such third party sites.</p>
                                                        <p>if Project-Forty-Six provides links or pointers to other websites or destinations, you should not infer or assume that Project-Forty-Six operates, 
                                                          controls, or is otherwise connected with these other websites or destinations. when you click on a link within the services,
                                                           Project-Forty-Six will not warn you that you have left the services and are subject to the terms and conditions (including privacy policies) of another website or destination.
                                                            In some cases, it may be less obvious than others that you have left the services and reached another website or destination.
                                                             Please be careful to read the terms of use and privacy policy of any other website or destination before you provide any confidential information or engage
                                                              in any transactions. 
                                                          You should not rely on these terms and conditions and/or privacy policy to govern your use of another website or destination.</p>
                                                          <p>Project-Forty-Six is not responsible for the content or practices of any website or destination other than the site, even if it links to the site and
                                                             even if the website or destination is operated by a company affiliated or otherwise connected with Project-Forty-Six. By using the services, you acknowledge and agree that 
                                                            Project-Forty-Six is not responsible or liable to you for any content or other materials hosted and served from any website or destination other than the site.</p>
                                                            <h1 className="TermsOfuseHeadings">Advertising</h1>
                                                            <p>Project-Forty-Six may integrate third party software development kits (sdks) with the site to improve placement of advertisements that may be better suited to users. 
                                                              Project-Forty-Six expressly disclaim any liability arising out of the advertisements, 
                                                              usage or viewing of these products or services advertised on our site or the (third party) content made available / hosted on the third party sites.</p>
                                                              <p>Project-Forty-Six takes no responsibility for advertisements or any third party material posted on the site nor does it take any responsibility 
                                                                for the products or services provided by advertisers. Any dealings you have with advertisers found while using the ser
                                                                vices are between you and the advertiser, 
                                                                and you agree that Project-Forty-Six is not liable for any loss or claim that you may have against an advertiser.</p>

                                                                <h1 className="TermsOfuseHeadings">Third party content</h1>
                                                                <p>Project-Forty-Six's role is limited to providing a communication platform along with hosting services to third parties,
                                                                   to enable the transmission of the third party content directly from third parties to you. The third party content on the site
                                                                    is directly uploaded onto the site by third parties who avail of Project-Forty-Six hosting services, without any intervention from Project-Forty-Six
                                                                     in the uploading / transmission process. Project-Forty-Six role is that of an 'intermediary' as defined under the information technology act, 2000 and the
                                                                      rules thereunder, with regard to the third party content. being an intermediary, Project-Forty-Six has no responsibility and / or liability in respect of any
                                                                       third party content on the site
                                                                  , including for intellectual property rights infringement, defamation, obscenity or any other violation under applicable law.</p>
                                                                  <p>Project-Forty-Six does not endorse, market, advertise or publicize any third party content on the site and is not responsible or liable for any third party content. 
                                                                    Project-Forty-Six role is limited to notifying you of the availability of the third party content on the site.</p>
                                                                    <p>Project-Forty-Six does not pre-screen the third party content and has no obligation to monitor any third party content. 
                                                                      Hence, Project-Forty-Six does not have actual or specific knowledge of any third party content on the site.
                                                                       However Project-Forty-Six at its discretion and in accordance with applicable law may monitor any third party content and may remove
                                                                        any third party content from the site if Project-Forty-Six determines in its sole discretion that such third party content is in violation of 
                                                                        this agreement or any applicable law. Project-Forty-Six, at its discretion, may review the third party content when, and only when, complaints are received from you. 
                                                                        Such actions do not in any manner negate or dilute Project-Forty-Six position as an intermediary or impose any liability on Project-Forty-Six with respect to third party content.</p>
                                                                        <p>Project-Forty-Six will consider all communications, requests and suggestions sent by you and other members of the public provided that
                                                                           such communications are sent in a bona fide manner in good faith in the interest of the site and public good. however Project-Forty-Six 
                                                                           is not under any obligation to act on any such communications, requests and suggestions or respond to any one. 
                                                                           Project-Forty-Six decision in this respect shall be final. 
                                                                           Specifically, if any such request relates to a request / demand to take down/ disable/ remove/ delete any third party content in the site,
                                                                            Project-Forty-Six is under no legal obligation to respond to or act on such requests. Project-Forty-Six will take such action as Project-Forty-Six is required to take under applicable law.
                                                                             If there is
                                                                           any valid court order or administrative order issued requiring Project-Forty-Six to take any action, then Project-Forty-Six will comply with such court order or administrative order.</p>

                                                                           <h1 className="TermsOfuseHeadings">Support</h1>
                                                                           <p>If you have any questions, 
                                                                            complaints or claims with respect to the site and/or services and/or content, then such correspondence should be directed to the address as given below.</p>
                                                                            <p>info@projectfortysix.com</p>
                                                                            <h1 className="TermsOfuseHeadings">Disclaimer of warranties</h1>
                                                                            <p>You agree that use of the services and the access to the site is at your own risk. the services including the site, the content, the video player,
                                                                               user material and any other materials contained on or provided through the site are provided "as is" and,
                                                                                to the fullest extent permitted by law, are provided without warranties of any kind, either express or implied. 
                                                                                Without limiting the foregoing, Project-Forty-Six does not make any warranties of fitness for a particular purpose, title, merchantability, completeness,
                                                                                 availability, security, compatibility or non-infringement; or that the services will be uninterrupted, free of viruses, malware or other harmful components, 
                                                                                 accurate, error free or reliable.</p>
                                                                            <h1 className="TermsOfuseHeadings">Limitation of liability</h1>
                                                                            <p>In no case shall Project-Forty-Six, its directors, officers, employees, affiliates, agents, contractors, principals, or licensors be liable for any direct,
                                                                               indirect, incidental, punitive, special, or consequential damages arising from your use of the services or any content or material thereof or for any other
                                                                                claim related in any way to your access of the site or use of the services or any content or material thereof, including, but not limited to, any errors or 
                                                                                omissions in any content or material, or any loss or damage of any kind incurred as a result of the use of the services or any content or material thereof,
                                                                                 even if advised of their possibility. Project-Forty-Six monetary liability for the services or the content or material thereof on the site shall be limited to USD 2000, 
                                                                                 where the laws do not allow the exclusion or 
                                                                              the limitation of liability for consequential or incidental damages, in such jurisdictions, Project-Forty-Six liability shall be limited to the extent permitted by law.</p>

                                                                              <h1 className="TermsOfuseHeadings">Indemnity</h1>
                                                                              <p>You agree to defend, indemnify and hold harmless Project-Forty-Six , its affiliates, officers, directors, employees and agents, from and against any and all claims,
                                                                                 damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorneys' fees) arising from:</p>

                                                                                 <ul>
                                                                                  <li>(i) your use of and access to the services;</li>
                                                                                  <li>(ii) your violation of any term of these terms and conditions;</li>
                                                                                  <li>(iii) your violation of any third party right, including without limitation any publicity, privacy, or intellectual property right;</li>
                                                                                  <li>(iv) your breach of any applicable laws; and</li>
                                                                                  <li>(v) any unauthorized, improper, illegal or wrongful use of your account by any person, including a third party, whether or not authorized or permitted by you. 
                                                                                    This indemnification obligation will survive the expiry or termination of these terms and conditions and your use of the service.</li>



                                                                                 </ul>
                                                                                 <h1 className="TermsOfuseHeadings">Governing law and jurisdiction</h1>
                                                                                 <p>The relationship between you and Project-Forty-Six shall be governed by the laws of india without
                                                                                   regard to its conflict of law provisions and for resolution of any dispute arising out of your use of the services.
                                                                                    Notwithstanding the foregoing, you agree that</p>
                                                                                    <ul>
                                                                                      <li>(i) Project-Forty-Six has the right to bring any
                                                                                         proceedings before any court/forum of competent jurisdiction and you irrevocably submit to the jurisdiction of such courts or forum; and</li>
                                                                                      <li>(ii) any proceeding brought by you shall be exclusively before the courts at mumbai, india.</li>
                                                                                    </ul>
                                                                                    <h1 className="TermsOfuseHeadings">Severability</h1>
                                                                                    <p>If any provision of these terms and conditions is held invalid, void, or unenforceable, 
                                                                                      hen that provision shall be considered severable from the remaining provisions, and the remaining provisions given full force and effect.</p>

                                                                                      <h1 className="TermsOfuseHeadings">Changes</h1>
                                                                                      <p>Project-Forty-Six reserves the right, at any time and from time to time, without prior notice to you, to update, revise, supplement,
                                                                                         and otherwise modify these terms of use and to impose new or additional rules, policies, terms, or conditions on your use of the service. any updates, 
                                                                                         revisions, supplements, modifications, and additional rules, policies, terms, and conditions (collectively referred to in this terms of use as "revised terms")
                                                                                          will be posted on the site and will be effective immediately after such posting and thereafter incorporated into these terms and conditions.
                                                                                           We recommend that you periodically check the site for the revised terms your continued use of the services will be deemed to constitute your acceptance of any and all such revised terms.</p>

                                                                                           <h1 className="TermsOfuseHeadings">Survival</h1>
                                                                                           <p>You acknowledge that your representations, undertakings, and warranties and the clauses relating to indemnities, limitation of liability,
                                                                                             grant of license, governing law, confidentiality shall survive the efflux of time and the termination of these terms and conditions.</p>
                                                                                             <h1 className="TermsOfuseHeadings">Entire agreement</h1>
                                                                                             <p>These terms and conditions and privacy policy constitute the entire agreement between you and Project-Forty-Six governing your use of the services, 
                                                                                              superseding any prior agreements between you and Project-Forty-Six regarding such use.</p>









             



              </div>






                            
                            </div>{/**privacyTermsWrapper */}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TermsOfUse;
