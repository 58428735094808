import React, { useState, useEffect } from "react";
import "./couponCodeModal.css";
import { service } from "../../network/service";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

const CouponCodeModal = ({ setCouponCodeModal }) => {
  const [input, setInput] = useState("");
  const [couponSuccess, setCouponSuccess] = useState(false);
  const [couponErrorMsg, setCouponErrorMsg] = useState("");

  const applyCoupons = async () => {
    service.couponCode(input).then((response) => {
      if (response.success === true) {
        if (response.data) {
          if (response.data.coupon_id) {
            localStorage.setItem("coupon_id", response.data.coupon_id);
            localStorage.setItem(
              "selected_amount",
              response.data.discounted_price
            );
            setCouponErrorMsg(null);

            setCouponSuccess(true);
          }
        } else {
          setCouponSuccess(false);

          setCouponErrorMsg(response.message);
        }
      }
    });
  };

  const onContinue = () => {
    setTimeout(async() => {
      
      let subscriptionId = localStorage.getItem("selectedSubId");
          let session;
          let couponId=localStorage.getItem("coupon_id");
          let coupon=input
          session = await service.stripeSession(
            subscriptionId,
            coupon,
            couponId

          );
          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({
            sessionId: session.id,
          });
          if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          }
    }, 1000);
  }

  return (
    <div className="couponCodeModal">
      <div className="overlayModal"></div>
      <div className="CouponCodeContainer">
        <label htmlFor="couponCode">Enter Coupon Code</label>
        <div className="inputContainer">
          <input
            type="text"
            id="couponCode"
            value={input || ""}
            onChange={(e) => {
              setInput(e.target.value);
            }}
          />
          <div className="applyButtonContainer">
            <button onClick={() => applyCoupons()}>Apply</button>
          </div>
        </div>
        {couponErrorMsg && <span className="error">{couponErrorMsg}</span>}
        {couponSuccess && <span className="success">Coupon Applied</span>}
        <div className="buttonContainer">
          <button onClick={()=>onContinue()}>Continue</button>
        </div>
      </div>
    </div>
  );
};

export default CouponCodeModal;
