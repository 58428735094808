import React, { useState, useEffect } from "react";
import Subscription from "./Subscription";
import { service } from "./service";
import { ToastsContainer, ToastsStore } from "react-toasts";

const SubscriptionContainer = (param, androidData, isAndroid) => {
  const [premiumFlag, setPremiumFlag] = useState();
  useEffect(() => {}, []);

  return (
    <section className="categoryWrapper" style={{background: '#182031'}}>
      <div className="_2vKa8"></div>
      <div className="container categoryHeadWrapper">
        
        <Subscription
          param={param.param}
          androidData={androidData}
          isAndroid={isAndroid}
        />
        <ToastsContainer store={ToastsStore} />
      </div>
    </section>
  );
};
export default SubscriptionContainer;
