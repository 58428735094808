import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import './subscription.css'
import { service } from "../../network/service";
import { useHistory } from "react-router-dom";
import CouponCodeModal from "../Modals/CouponCodeModal";

const Subscription = (param, androidData, isAndroid) => {

  const isLoggedIn = service.getCookie("isLoggedIn");
  const history = useHistory();
  const [currentPlan, setCurrentPlan] = useState('Monthly')
  const [monthTab, setMonthTab] = useState('item active')
  const [yearTab, setYearTab] = useState('item')
  const [modalIsOpen, setIsOpen] = useState(false);
  const [couponCodeModal,setCouponCodeModal] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSelectPlan = (plan) => {
    localStorage.setItem("selectedSubId", plan.publisher_subscription_id);
    localStorage.setItem("selectedAmount", plan.price);
    localStorage.setItem("newFlow", "1");
    setTimeout(async () => {

      if (isLoggedIn == "true") {
        setCouponCodeModal(true)
        // let subscriptionId = plan.publisher_subscription_id;
        // let session;
        // localStorage.removeItem("coupon_id");
        // session = await service.stripeSession(
        //   subscriptionId
        // );
        // const stripe = await stripePromise;
        // const result = await stripe.redirectToCheckout({
        //   sessionId: session.id,
        // });
        // if (result.error) {
        //   // If `redirectToCheckout` fails due to a browser or network
        //   // error, display the localized error message to your customer
        //   // using `result.error.message`.
        // }
      } else {
        setIsOpen(false);
        history.push({
          pathname: "/register",
          state: {
            paymentData: plan,
            androidData: androidData,
            isAndroid: isAndroid,
            from: '/SubscriptionList'
          },
        });
      }

    }, 1000);
  };

  const subscriptionList = param && param.param && param.param.map((item, index) => {
    let discountedPrice = item.discounted_price
    let couponCode = item.coupon_code
    let subTitle = ''
    let monthlyPrice = item.subscription_type_name == 'Yearly' ? Math.ceil(item.price / 12): Math.ceil(item.price)
    discountedPrice = item.subscription_type_name == 'Yearly' ? Math.ceil(discountedPrice / 12): Math.ceil(discountedPrice)
    if (item.subscription_name == 'ACTIVIST') {
      subTitle = 'MOST POPULAR'
    } else if (item.subscription_name == 'INFLUENCER') {
      subTitle = 'BEST VALUE'
    }



    return <div className="item">
      {
        couponCodeModal&&
        <CouponCodeModal setCouponCodeModal={setCouponCodeModal}/>
      }
      <div className="wrapper">
        <div class="sub-title-section" ><h6 >{subTitle}</h6></div>
        <div className="title-section">
          <h4>{item.subscription_name}</h4>
          <hr></hr>
        </div>
        <div class="coupon-price-section">
          <h5 >{couponCode&&item.subscription_type_id===3||item.subscription_type_id===4  ?"$" + monthlyPrice+"/mo":couponCode&&"$" + monthlyPrice}</h5>
        </div>
        <div className="price-section">
          <h5>{discountedPrice&&item.subscription_type_id===3||item.subscription_type_id===4 ? "$" + discountedPrice+ "/mo":discountedPrice?"$" + discountedPrice :item.subscription_type_id===3||item.subscription_type_id===4? "$" + monthlyPrice + "/mo":"$" + monthlyPrice}</h5>
        </div>
        <div className="coupon-code-section">
          <h5>{couponCode && "*with code " + couponCode}</h5>
        </div>
        {
          item.subscription_type_id ===3 | item.subscription_type_id ===4 ?(
        <div className="price-bill-section">
          <h5>{"Billed "+ item.subscription_type_name}</h5>
        </div>

          ):null
        }
        <hr className="feature-devider" />
        <div className={couponCode? "": "features-wrapper"}>
        {
          item.features && item.features.map((feature) => {
            return <div className="info-section">
              <span>{feature}</span>
            </div>
          })
        }
        </div>
        <div>
          <button onClick={() => {
            onSelectPlan(item)
          }}>choose plan</button>
        </div>
      </div>
    </div>

  })

  const changeCurrentPlan = (plan) => {
    setCurrentPlan(plan)
    if (plan == 'Monthly') {
      setMonthTab('item active')
      setYearTab('item')
    } else {
      setMonthTab('item')
      setYearTab('item active')
    }
  }

  return (
    <div className="membership-container">
      <div className="wrapper">
        <div>
          <h1 className="membership-label">memberships</h1>
        </div>

        <div className="subscription-list">
          <div className="inner-wrapper">

            {subscriptionList}

          </div>
        </div>


      </div>
    </div>
  );
};
export default Subscription;
