import React, { useState, useEffect } from "react";
import SubscriptionContainer from "./SubscriptionContainer";
import { useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { service } from "./service";
import { service as mainService } from "../../network/service";
import "./SubscriptionList.css";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

const SubscriptionList = (state) => {
  const dispatch = useDispatch();
  const [subscription, setSubscription] = useState([]);
  const [userSubscription, setUserSubscription] = useState([]);
  const [androidData, setAndroidData] = useState("");
  const [isAndroid, setIsAndroid] = useState(false);
  let urlParams = new URLSearchParams(window.location.search);
  let userID = service.getCookie("userId")
  let androidToken = urlParams.get("antkn");
  localStorage.setItem('is_upgrade', false)

  var selectedVideoId =urlParams.get("video_id")?urlParams.get("video_id"):state.location.state != undefined ? state.location.state.videoData : null;
  useEffect(() => {

    dispatch({
      type: "IS_UPGRADE",
      payload: false,
    });

    var referralSubscription = state.location.state != undefined ? state.location.state.referralSubscription : null;
      
    var singleObj = [];

    if (androidToken) {
      let deviceId = urlParams.get("deviceId");
      if (deviceId) {
        localStorage.setItem("deviceId", deviceId);
      }
      dispatch({ type: "SET_ANDROID" });
      selectedVideoId = urlParams.get("vd");
      service.androidTokeDecode(androidToken).then(async (response) => {
        if (response.success == true) {
          localStorage.setItem("access-token", androidToken);
          service.setCookie("userId", response.data[0].user_id, 15);
          userID = response.data[0].user_id
          localStorage.setItem("userId", userID);
          localStorage.setItem("userName", response.data[0].first_name);
          localStorage.setItem("isAndroid", "true");
          service.setCookie("isLoggedIn", "true", 15);
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("deviceType", "android-web");
          setAndroidData(response.data);
          setIsAndroid(true);

          let coupon_id = urlParams.get("ci");
          let coupon = urlParams.get("cc");
          let subscriptionId = urlParams.get("sb");
          let session;
          if (coupon_id && coupon) {
            session = await mainService.stripeSession(
              subscriptionId,
              coupon,
              coupon_id
            );
          } else {
            localStorage.removeItem("coupon_id");
            session = await mainService.stripeSession(
              subscriptionId
            );
          }
          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({
            sessionId: session.id,
          });
          if (result.error) {

            localStorage.clear()
            // localStorage.setItem('isValidUser', 'false')

            setTimeout(() => {
              window.location.href = "/home";
            }, 2000);
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          }
        }
      });
    } else {
      if (selectedVideoId) {
        service.videoSubscription(selectedVideoId).then((response) => {
          var data = response.data;
          if (data != undefined)
            data.map((item, index) => {
              singleObj.push(item);
            });
          setSubscription(singleObj);
        });
      } else {
        service.publisherSubscription().then((response) => {
          var data = response.data;
          if (data != undefined)
            data
              .filter(
                (item) =>
                  item.subscription_type_id == 3 ||
                  item.subscription_type_id == 4
              )
              .map((item, index) => {
                singleObj.push(item);
              });
          if (!referralSubscription) {
            setSubscription(singleObj);
          } else {
            let referralSubscriptionList = singleObj.filter(subscription => referralSubscription.includes(subscription.publisher_subscription_id));
            setSubscription(referralSubscriptionList);
          }

        });
      }
    }
  }, []);

  return (
    <div className="pageWrapper searchPageMain sub__container marginAdjust">
      <div className="topContainer">
        <div className="homepageWrapper menuCloseJS closeMenuWrapper">
          <div className="allCategoryContainer">
            <div key={1}>
              {!androidToken ? <SubscriptionContainer
                param={subscription}
                androidData={androidData}
                isAndroid={isAndroid}
              /> : <div className="loader-wrapper"><span>Loading...</span></div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubscriptionList;
