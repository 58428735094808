import React, { useState, useEffect } from 'react';
const AboutUs = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<div className="menuCloseJS closeMenuWrapper">
				<div className="privacyTermsFlex privacyTermsColor privacyTermsScroll">
					<div className="privacyTermsWidth">
						<h1 className="privacyTermsHeading">About Us</h1>
						<div className="privacyTermsWrapper">
							<div className="privacyTermsContent">
								<p><b>Project Forty-Six</b> is a conservative news and information service that was created to combat censorship and circumvent the chokehold Big-Tech has on entry to the public square.

									Our goal is to provide insight into Oklahoma’s most pressing social and political issues.</p>

								<p>We bring you original content, in-depth interviews, and inside information you will not find anywhere else. Our focus is to maximize awareness of the issues that matter most to you and those that you can affect the most.

									<b>Project Forty-Six</b> aims to offer you content that provides you with the information you need at the local level. What happens in our communities matters so much more than what’s going on in DC.

									We will keep you engaged and informed at the State Capitol, at School Board meetings, and at City Council meetings. Wherever public policy is being decided, <b>Project Forty-Six</b> will bring you to the frontlines of every battlefield.

									Conservatives must be informed and engaged at every level of Oklahoma politics. The values we cherish in Oklahoma — life, liberty, and the pursuit of happiness — are under a coordinated attack by Marxist radicals who intend to destroy every American institution. The legacy media refuses to acknowledge or address this crisis — so we are stepping up.

									We invite you to enjoy our free content and we encourage you to subscribe. A monthly subscription will give you access to exclusive interviews, insightful articles from Oklahoma’s leading conservatives, and you will help support a critical public service — a conservative source for Oklahoma news and information.

									Being engaged and informed is a charge that Conservatives MUST adhere to, especially Oklahoma Conservatives. We have an obligation to keep our GREAT state RED and ensure it does not fall into the hands of the radical Left like we have witnessed in other states over the years.

									This is a FIGHT, a fight against Big Tech and a complicit media that has lost credibility.</p>

								<p></p>

								<h6>Who is Behind <b>Project Forty-Six</b>?</h6>
								<p>Co-Founder Miles Rahimi returned from serving on Active Duty in the United States Navy in 2020 and unsuccessfully ran for Congress. Despite the loss, he gained a deep understanding of just how fractured Conservatives are in Oklahoma.

									During the 2020 Election, Miles witnessed firsthand how biased the mainstream media was and how the need for focused local media was desperately needed.
								</p>
								<p><i>“….national Conservative talk radio, podcasts, and the like have one goal and that is to get you upset on the issues you can’t control.



									Every community has their own Rush Limbaugh, Glenn Beck, or Ben Shapiro, and we need to raise up those voices so we can drive action on the issues we can control.” <b>-Miles Rahimi, 2020</b></i></p>

								<p>Armed with the knowledge that God didn’t bring him out of the Navy to lose a Congressional race, Miles and his wife, Colin, put forth their gifts and talents to create <b>Project Forty-Six</b>.

									Since its launch, <b>Project Forty-Six</b> has quickly become Oklahoma’s fastest growing digital platform.

									<b>Project Forty-Six</b> does not rely on YouTube, Facebook, Vimeo, or any video platform and is entirely self-sustaining to ensure that Conservatives are not censored nor taken off the air.</p>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default AboutUs;
