import React, { useState, useEffect } from "react";
import FacebookLogin from "react-facebook-login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import { loadStripe } from "@stripe/stripe-js";
import "react-dropdown/style.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { service } from "../../network/service";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Redirect, Link, useLocation } from "react-router-dom";
import { deviceDetect } from "../../Utils/utils";
import $ from "jquery";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

const Register = (state) => {
  let location = useLocation();
  if (location.state && location.state.from) {
    localStorage.setItem("location", location.state.from.pathname);
  }
  const functionOnclick = (path) => {
    history.push({ pathname: path });
  };
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  let userId = service.getCookie("userId");
  let prevDomain = document.referrer
    .replace("http://", "")
    .replace("https://", "")
    .split(/[/?#]/)[0];
  let currentDomain = window.location.href
    .replace("http://", "")
    .replace("https://", "")
    .split(/[/?#]/)[0];
  if (isLoggedIn === "true" && userId) {
    return <Redirect to="/home" />;
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const [isDesktop, setIsDesktop] = useState(deviceDetect());
  const [firstname, setFirstName] = useState("");
  const [facebookData, setFacebookData] = useState(null);
  const [facebookId, setFacebookId] = useState("");
  const [facebookEmail, setFacebookEmail] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [userRegisterId, setUserRegisterId] = useState("");
  const [verification_code, setVerification] = useState("");
  const [isRegister, setIsRegister] = useState(true);
  const [isFbAcive, setIsFbAcive] = useState(true);
  const [isSuccessMsg, setIsSuccessMsg] = useState(false);
  const [msgSuccess, setMsgSucess] = useState("");
  const [isErrorMsg, setIsErrorMsg] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [isErrorVerifyMsg, setIsErrorVerifyMsg] = useState(false);
  const [msgErrorVerify, setMsgErrorVerify] = useState("");
  const [isEmailExistMsg, setIsEmailExistMsg] = useState(false);
  const [couponId, setCouponId] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponNotValid, setCouponNotValid] = useState(false);
  const [isCouponEnabled, setIsCouponEnabled] = useState(false);
  const [success, setSuccess] = useState(false);
  const [notExist, setnotExist] = useState(false);
  const [Invalid, setInvalid] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState("");
  const [haveCoupon, setHaveCoupon] = useState(true);
  const [ConfirmMail, setConfirmMail] = useState("");
  const [referralSubscriptionName, setReferralSubscriptionName] = useState();
  const [referralSubscriptionId, setReferralSubscriptionId] = useState();
  const [haveReferral, setHaveReferral] = useState(false);
  const [loading, setLoading] = useState(true);

  window.onload = () => {
    const myInput = document.getElementById("confirm_mail");
    myInput.onpaste = (e) => e.preventDefault();
  };

  const [values, setValues] = useState({
    firstname: "",
    email: "",
    password: "",
    password2: "",
    age: "",
    confirm_email: "",
  });
  const [errors, setErrors] = useState({
    firstname: "Name",
    email: "Email",
    password: "Password",
    password2: "Confirm Password",
    age: "Age Group",
    confirm_email: "Confirm Your Email",
  });
  const [valuesVerify, setValuesVerify] = useState({
    verification_code: "",
  });
  const [errorsVerify, setErrorsVerify] = useState({
    verification_code: "Verification Code",
  });
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [eye1, setEye1] = useState(<FontAwesomeIcon icon={faEye} />);
  const [isEye1, setIsEye1] = useState(false);
  const [isEye2, setIsEye2] = useState(false);
  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
    setEye1(
      passwordShown1 ? (
        <FontAwesomeIcon icon={faEye} />
      ) : (
        <FontAwesomeIcon icon={faEyeSlash} />
      )
    );
  };
  let FBData = null;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const referralCode = queryParams.get("ref");

    if (state.location.state && state.location.state.paymentData) {
      setLoading(false);
      let paymentData =
        state.location.state && state.location.state.paymentData;
      setReferralSubscriptionName(
        paymentData.subscription_name + "-" + paymentData.subscription_type_name
      );
    }

    if (referralCode) {
      setCoupon(referralCode);

      service.validateReferralCode(referralCode).then((response) => {
        if (response.data) {
          const noOfSubscriptions = response.data[0].subscriptions.length;
          if (noOfSubscriptions == 1) {
            const selectedSubscription = response.data[0].subscriptions[0];
            localStorage.setItem(
              "selectedSubId",
              selectedSubscription.subscription_id
            );
            localStorage.setItem("newFlow", "1");
            setIsCouponEnabled(true);
            setCouponId(response.data[0].id);
            setReferralSubscriptionId(selectedSubscription.subscription_id);
            setReferralSubscriptionName(
              selectedSubscription.subscription_name +
                "-" +
                selectedSubscription.subscription_type
            );
            setHaveReferral(true);
            setLoading(false);
          } else if (noOfSubscriptions > 1) {
            const referralSubscriptionIds = response.data[0].subscriptions.map(
              (item) => {
                return item.subscription_id;
              }
            );
            localStorage.setItem("referralCode", response.data[0].name);
            localStorage.setItem("referralId", response.data[0].id);
            localStorage.setItem("haveReferral", "true");
            setTimeout(() => {
              history.push({
                pathname: "/SubscriptionList",
                state: {
                  referralSubscription: referralSubscriptionIds,
                },
              });
            }, 3000);
          } else if (noOfSubscriptions == 0) {
            localStorage.setItem("referralCode", referralCode);
            localStorage.setItem("haveReferral", "zero");
            setTimeout(() => {
              history.push({
                pathname: "/SubscriptionList",
              });
            }, 3000);
          }
        }
      });
    }

    window.scrollTo(0, 0);
    $(".inputText").focus(function () {
      $(this).parent(".input").addClass("inputActive");
    });
    $(".inputText").focusout(function () {
      $(this).parent(".input").removeClass("inputActive");
    });
    if (state.location.state) {
      if (state.location.state.facebookData) {
        let FBData = state.location.state.facebookData;
        setIsFbAcive(false);
        setFacebookId(FBData.id);
        setFacebookData(state.location.state.facebookData);
        if (FBData.email) {
          setValues({
            ...values,
            ["firstname"]: FBData.first_name,
            ["email"]: FBData.email,
          });
        } else {
          setValues({
            ...values,
            ["firstname"]: FBData.first_name,
          });
        }
      }
    }
  }, []);

  const handleCoupon = (e) => {
    setCoupon(e.target.value);
  };
  const applyCoupons = async () => {
    service.couponCode(coupon).then((response) => {
      if (response.data) {
        if (response.data.coupon_id) {
          let coupon_id = response.data.coupon_id;
          localStorage.setItem("coupon_id", coupon_id);
          setIsCouponEnabled(true);
          setSuccess(true);
          setnotExist(false);
          setInvalid(false);
          setCouponNotValid(false);
          setDiscountedPrice(true);
          setPaymentResponse(response.data.discounted_price);
          let selected_amount = response.data.discounted_price;
          localStorage.setItem("selected_amount", selected_amount);
        }
      } else if (response.message == "Coupon does not exist") {
        setnotExist(true);
        setSuccess(false);
        setInvalid(false);
        setCouponNotValid(false);
        setDiscountedPrice(false);
      } else if (
        response.message == "Coupon is not valid for this subscription."
      ) {
        setnotExist(false);
        setSuccess(false);
        setInvalid(false);
        setCouponNotValid(true);
        setDiscountedPrice(false);
      } else {
        setInvalid(true);
        setSuccess(false);
        setnotExist(false);
        setCouponNotValid(false);
        setDiscountedPrice(false);
      }
    });
  };

  const validateEmail = (email) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  };
  const validateVerify = (verification_code) => {
    if (/^\d*$/.test(verification_code.trim())) {
      return true;
    }
    return false;
  };
  const validateName = (firstname) => {
    if (/^[a-z A-Z 0-9_.-]*$/.test(firstname.trim())) {
      return true;
    }
    return false;
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null,
    });

    if (name == "password") {
      if (value.length > 0) {
        setIsEye1(true);
      } else {
        setIsEye1(false);
      }
    } else if (name == "password2") {
      if (value.length > 0) {
        setIsEye2(true);
      } else {
        setIsEye2(false);
      }
    }
  };
  const onChangeHandlerVerify = (e) => {
    const { name, value } = e.target;

    setValuesVerify({
      ...valuesVerify,
      [name]: value,
    });
  };
  const validationVerify = () => {
    let errorsVerify = {};
    let formIsValid = true;
    if (valuesVerify.verification_code.trim()) {
      var errorMsg = validateVerify(valuesVerify.verification_code);
      if (errorMsg === true) {
        errorsVerify.verification_code = "Verification Code";
        setVerification("");
      } else {
        formIsValid = false;
        setVerification(" Input--errored");
        errorsVerify.verification_code = "OTP contain numeric only";
      }
    } else {
      formIsValid = false;
      errorsVerify.verification_code = "Required Verification Field";
      setVerification(" Input--errored");
    }
    setErrorsVerify(errorsVerify);
    return formIsValid;
  };
  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (values.firstname.trim()) {
      // var errorMsg = validateName(values.firstname);
      // if (errorMsg === true) {
      errors.firstname = "First Name";
      setFirstName("");
      // } else {
      //   formIsValid = false;
      //   setFirstName(" Input--errored");
      //   errors.firstname = "Alphabets only";
      // }
    } else {
      formIsValid = false;
      errors.firstname = "Required Name Field";
      setFirstName(" Input--errored");
    }
    if (values.email.trim()) {
      var errorMsg = validateEmail(values.email);
      if (errorMsg === true) {
        errors.email = "Email";
        setEmail("");
      } else {
        formIsValid = false;
        setEmail(" Input--errored");
        errors.email = "Invalid email Address";
      }
    } else {
      formIsValid = false;
      errors.email = "Required Email Field";
      setEmail(" Input--errored");
    }
    if (values.password.trim()) {
      if (values.password.length >= 6 && values.password.length <= 30) {
        if (values.password.trim()) {
          errors.password = "Password";
          setPassword("");
          errors.password2 = "Confirm Password";
          setPassword2("");
        } else {
          errors.password = "Password";
          setPassword("");
        }
      } else {
        formIsValid = false;
        setPassword(" Input--errored");
        errors.password = "Length must be between 6 and 30";
      }
    } else {
      formIsValid = false;
      setPassword(" Input--errored");
      errors.password = "Required Password Field";
    }
    if (values.email === values.confirm_email) {
      var errorMsg = validateEmail(values.confirm_email);
      if (errorMsg === true) {
        errors.confirm_email = "Confirm Your Email";
        setConfirmMail("");
      } else {
        formIsValid = false;
        setConfirmMail(" Input--errored");
        errors.confirm_email = "Email Addresses are matching";
      }
    } else {
      formIsValid = false;
      errors.confirm_email = "Email Addresses are not matching";
      setConfirmMail(" Input--errored");
      setErrors(errors);
    }

    return formIsValid;
  };


  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (validation()) {
      const registerVlaues = {
        ...values,
        ["ReferralCode"]: coupon
          ? coupon
          : localStorage.getItem("referralCode"),
      };
      service.register(registerVlaues, facebookId).then(async (response) => {
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userName", response.data[0].first_name);
        service.setCookie("userId", response.data[0].user_id, 30);
        service.setCookie("isLoggedIn", "true", 30);

        // if (response.success == true) {
        //   setUserRegisterId(response.user_id);
        //   setIsRegister(false);
        //   setMsgSucess(
        //     "OTP sent to your Email Id (Note:  If you do not find the email in your inbox, please check your spam filter or bulk email folder)"
        //   );
        //   setIsSuccessMsg(true);
        //   setTimeout(function () {
        //     setIsSuccessMsg(false);
        //   }, 5000);

        // }

        if (response.success == true) {
          setUserRegisterId(response.user_id);
          setIsRegister(false);

          setIsSuccessMsg(true);
          setTimeout(function () {
            setIsSuccessMsg(false);
          }, 5000);

          let prevLocation = localStorage.getItem("location");

          // if (prevDomain == currentDomain) {
          //   if (prevLocation === "/tv") {
          //     history.push("/tv");
          //   } else {
          //     history.goBack();
          //   }
          // } else {
          if (prevLocation === "/tv") {
            history.push("/tv");
          } else {
            const multiReferralSubscription =
              localStorage.getItem("haveReferral");
            if (
              localStorage.getItem("newFlow") ||
              haveReferral ||
              multiReferralSubscription
            ) {
              let coupon_id = couponId
                ? couponId
                : localStorage.getItem("coupon_id");
              let paymentData =
                state.location.state && state.location.state.paymentData;
              let session;
              if (
                isCouponEnabled == true ||
                multiReferralSubscription == "true"
              ) {
                try {
                  session = await service.stripeSession(
                    haveReferral
                      ? referralSubscriptionId
                      : paymentData.publisher_subscription_id,
                    multiReferralSubscription == "true"
                      ? localStorage.getItem("referralCode")
                      : coupon,
                    multiReferralSubscription == "true"
                      ? localStorage.getItem("referralId")
                      : coupon_id
                  );
                  if (session && session.success === false) {
                    localStorage.removeItem("isLoggedIn");
                    localStorage.removeItem("userName");
                    service.eraseCookie("userId");
                    service.eraseCookie("isLoggedIn");
                  }
                } catch (err) {}
              } else {
                localStorage.removeItem("coupon_id");
                session = await service.stripeSession(
                  paymentData.publisher_subscription_id
                );
              }

              if (session.id) {
                const stripe = await stripePromise;
                const result = await stripe.redirectToCheckout({
                  sessionId: session.id,
                });
                if (result.error) {
                  // If `redirectToCheckout` fails due to a browser or network
                  // error, display the localized error message to your customer
                  // using `result.error.message`.
                  localStorage.setItem("isValidUser", "false");

                  localStorage.clear();
                } else {
                  dispatch({ type: "LOGIN", payload: true });
                  localStorage.setItem("isLoggedIn", true);
                  localStorage.setItem("userName", response.data[0].first_name);
                  service.setCookie("userId", response.data[0].user_id, 30);
                  service.setCookie("isLoggedIn", "true", 30);
                  setMsgSucess("You are successfully registered");
                  setIsSuccessMsg(true);
                  setTimeout(function () {
                    setIsSuccessMsg(false);
                  }, 5000);
                  localStorage.removeItem("referralCode");
                  localStorage.removeItem("referralId");
                  localStorage.removeItem("haveReferral");
                }
              }
            } else {
              history.push({
                pathname: "/SubscriptionList",
              });
            }
          }
          // }
        }
        //////////////////////////////////////////////////////////////////////////////////////////////////////////

        // else if (response.status == 0) {
        //     setMsgError('There was an error during registration');
        //     setIsErrorMsg(true);
        //     setTimeout(function () {
        //         setIsErrorMsg(false);
        //     }, 5000);

        // }
        else if (response.success == false) {
          setMsgError("Already registered user");
          setIsErrorMsg(true);
          setTimeout(function () {
            setIsErrorMsg(false);
          }, 5000);
        }
      });
      
    }
  };
  const onVerifyHandler = (e) => {
    e.preventDefault();

    if (validationVerify()) {
      service
        .verifyEmail(valuesVerify, userRegisterId)
        .then(async (response) => {
          if (response.success == true) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("userName", response.data[0].first_name);
            service.setCookie("userId", response.data[0].user_id, 30);
            service.setCookie("isLoggedIn", "true", 30);
            setMsgSucess("You are successfully registered");
            setIsSuccessMsg(true);
            setTimeout(function () {
              setIsSuccessMsg(false);
            }, 5000);
            dispatch({ type: "LOGIN", payload: true });
            let prevLocation = localStorage.getItem("location");

            if (prevDomain == currentDomain) {
              if (prevLocation === "/tv") {
                history.push("/tv");
              } else {
                history.goBack();
              }
            } else {
              if (prevLocation === "/tv") {
                history.push("/tv");
              } else {
                if (localStorage.getItem("newFlow")) {
                  let coupon_id = localStorage.getItem("coupon_id");
                  //
                  let paymentData = state.location.state.paymentData;
                  let session;
                  if (isCouponEnabled == true) {
                    session = await service.stripeSession(
                      paymentData.publisher_subscription_id,
                      coupon,
                      coupon_id
                    );
                  } else {
                    localStorage.removeItem("coupon_id");
                    session = await service.stripeSession(
                      paymentData.publisher_subscription_id
                    );
                  }
                  const stripe = await stripePromise;
                  const result = await stripe.redirectToCheckout({
                    sessionId: session.id,
                  });
                  if (result.error) {
                    localStorage.setItem("isValidUser", "false");

                    localStorage.clear();
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer
                    // using `result.error.message`.
                  }
                } else {
                  window.location.href = "/home";
                }
              }
            }
          } else if (response.success == false) {
            setMsgErrorVerify("Invalid OTP");
            setIsErrorVerifyMsg(true);
            setTimeout(function () {
              setIsErrorVerifyMsg(false);
            }, 5000);
          }
        });
    }
  };
  async function analyticsDevice() {
    await service
      .getLocation()
      .then((response) => {
        let currentLocation = {};
        currentLocation["country_code"] = response.data.countryCode;
        currentLocation["country_name"] = response.data.country;
        currentLocation["city"] = response.data.city;
        currentLocation["latitude"] = response.data.lat;
        currentLocation["longitude"] = response.data.lon;
        currentLocation["IPv4"] = response.data.query;
        currentLocation["state"] = response.data.region;
        localStorage.setItem(
          "currentLocation",
          JSON.stringify(currentLocation)
        );
        service.analytics().then((response) => {
          if (response.message) {
            service.setCookie("device_analytics", true);
          }
        });
      })
      .catch((error) => {
        service.analytics().then((response) => {
          if (response.message) {
            service.setCookie("device_analytics", true);
          }
        });
      });
  }
  const responseFacebook = (response) => {
    FBData = response;
    setFacebookId(FBData.id);
    setFacebookEmail(FBData.email);
    setFacebookData(response);
    var name = response.first_name;
    // if (response) {
    service
      .facebookLogin(response.id, response.email, name)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data[0];
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("userName", data.first_name);
          service.setCookie("isLoggedIn", "true", 30);
          service.setCookie("userId", data.user_id, 30);
          dispatch({ type: "LOGIN", payload: true });

          let prevLocation = localStorage.getItem("location");
          let analyticsVal = service.getCookie("device_analytics");
          if (analyticsVal) {
            if (analyticsVal === "true") {
              let storedData = service.getCookie("deviceAnalyticsCheck");
              let deviceId = localStorage.getItem("deviceId");
              let presentData = deviceId + data.user_id;
              if (storedData !== presentData) {
                service.setCookie("deviceAnalyticsCheck", presentData, 30);
                analyticsDevice();
              }
            } else {
              let deviceId = localStorage.getItem("deviceId");
              let deviceAnalyticsCheck = deviceId + data.user_id;
              service.setCookie(
                "deviceAnalyticsCheck",
                deviceAnalyticsCheck,
                30
              );
              analyticsDevice();
            }
          } else {
            let deviceId = localStorage.getItem("deviceId");
            let deviceAnalyticsCheck = deviceId + data.user_id;
            service.setCookie("deviceAnalyticsCheck", deviceAnalyticsCheck, 30);
            analyticsDevice();
          }

          if (prevLocation === "/tv") {
            history.push("/tv");
          } else if (location.state && location.state.from) {
            history.push({
              pathname: location.state.from.pathname,
              search: encodeURI(location.state.from.search),
              state: { item: response.data },
            });
          } else {
            history.goBack();
          }
        } else if (response.status == 204) {
          confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            message: "Do you want to link your Facebook account?",
            buttons: [
              {
                label: "Yes",
                onClick: () => onFBLink(),
              },
              {
                label: "No",
                onClick: () => onFBNoLink(),
              },
            ],
          });
        }
        // else if (response.success == true) {
        //   localStorage.setItem("isLoggedIn", true);
        //   localStorage.setItem("userName", response.data[0].first_name);
        //   service.setCookie("userId", response.data[0].user_id, 30);
        //   service
        //     .userSubscription(response.data[0].user_id)
        //     .then((response) => {
        //       // if (response.forcibleLogout == false) {
        //       service.setCookie("isLoggedIn", "true", 30);
        //       var user_sub = response.data;
        //       if (user_sub.length > 0) {
        //         service.setCookie("isLoggedIn", "true", 30);
        //         setMsgSucess("You are successfully registered");
        //         setIsSuccessMsg(true);
        //         setTimeout(function () {
        //           setIsSuccessMsg(false);
        //         }, 5000);
        //         dispatch({ type: "LOGIN", payload: true });

        //         let prevLocation = localStorage.getItem("location");
        //         if (prevLocation === "/tv") {
        //           history.push("/tv");
        //         } else if (location.state && location.state.from) {
        //           history.push({
        //             pathname: location.state.from.pathname,
        //             search: encodeURI(location.state.from.search),
        //             state: { item: response.data },
        //           });
        //         } else {
        //           history.goBack();
        //         }
        //       } else {
        //         service.setCookie("isLoggedIn", "true", 30);
        //         setMsgSucess("You are successfully registered");
        //         setIsSuccessMsg(true);
        //         setTimeout(function () {
        //           setIsSuccessMsg(false);
        //         }, 5000);
        //         dispatch({ type: "LOGIN", payload: true });
        //         let prevLocation = localStorage.getItem("location");
        //         if (prevLocation === "/tv") {
        //           history.push("/tv");
        //         } else if (location.state && location.state.from) {
        //           history.push({
        //             pathname: location.state.from.pathname,
        //             search: encodeURI(location.state.from.search),
        //             state: { item: response.data },
        //           });
        //         } else {
        //           history.goBack();
        //         }
        //       }

        //       //     return false;
        //       // }
        //     });
        // }
        else if (response.status == 102) {
          setMsgError("There was an error during registration");
          setIsErrorMsg(true);
          setTimeout(function () {
            setIsErrorMsg(false);
          }, 5000);
        } else if (response.status == 103) {
          setIsFbAcive(false);
          if (FBData.email) {
            setValues({
              ...values,
              ["firstname"]: FBData.first_name,
              ["email"]: FBData.email,
            });
          } else {
            setValues({
              ...values,
              ["firstname"]: FBData.first_name,
            });
          }
        } else if (response.status == 101) {
          setUserRegisterId(response.data[0].user_id);
          setIsRegister(false);
          setMsgSucess(
            "OTP sent to your Email Id (Note:  If you do not find the email in your inbox, please check your spam filter or bulk email folder)"
          );
          setIsSuccessMsg(true);
          setTimeout(function () {
            setIsSuccessMsg(false);
          }, 5000);
        } else {
          setMsgError("There was an error during registration");
          setIsErrorMsg(true);
          setTimeout(function () {
            setIsErrorMsg(false);
          }, 5000);
        }
      });
    // }
  };
  const onFBLink = () => {
    service.facebokLink(FBData.id, FBData.email).then((response) => {
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("userName", response.data[0].first_name);
      service.setCookie("userId", response.data[0].user_id, 30);
      service.userSubscription(response.data[0].user_id).then((response) => {
        // if (response.forcibleLogout == false) {
        service.setCookie("isLoggedIn", "true", 30);
        var user_sub = response.data;
        if (user_sub.length > 0) {
          service.setCookie("isLoggedIn", "true", 30);
          setMsgSucess("You are successfully registered");
          setIsSuccessMsg(true);
          setTimeout(function () {
            setIsSuccessMsg(false);
          }, 5000);
          dispatch({ type: "LOGIN", payload: true });
          let prevLocation = localStorage.getItem("location");
          if (prevLocation === "/tv") {
            history.push("/tv");
          } else if (location.state && location.state.from) {
            history.push({
              pathname: location.state.from.pathname,
              search: encodeURI(location.state.from.search),
              state: { item: response.data },
            });
          } else {
            history.goBack();
          }
        } else {
          service.setCookie("isLoggedIn", "true", 30);
          setMsgSucess("You are successfully registered");
          setIsSuccessMsg(true);
          setTimeout(function () {
            setIsSuccessMsg(false);
          }, 5000);
          dispatch({ type: "LOGIN", payload: true });
          let prevLocation = localStorage.getItem("location");
          if (prevLocation === "/tv") {
            history.push("/tv");
          } else if (location.state && location.state.from) {
            history.push({
              pathname: location.state.from.pathname,
              search: encodeURI(location.state.from.search),
              state: { item: response.data },
            });
          } else {
            history.goBack();
          }
        }

        //     return false;
        // }
      });
    });
  };
  const onFBNoLink = () => {
    setIsEmailExistMsg(true);
    setTimeout(function () {
      setIsEmailExistMsg(false);
    }, 5000);
  };
  const onSignIn = () => {
    history.push("/signin");
  };

  return (
    <div className="pageWrapper searchPageMain">
      <div className="topContainer">
        <div
          className={`menuCloseJS closeMenuWrapper ${
            isRegister ? "regnPage" : "regnPage1"
          }`}
        >
          <div className="container">
            <div className="row regnWrapper">
              <div className="col col-9 col-lg-6 col-xl-6 col-xxl-5">
                {isRegister && !loading ? (
                  <div>
                    <h3 className="H3">Let's get you set up!</h3>
                    {/* {isFbAcive && (
                    <div>
                      <div rel="noopener" target="_self">
                        <button className="button buttonLarge buttonBlock registerFacebook">
                          <div className="buttonBg rounderbutton"></div>
                          <FacebookLogin
                            appId="4691963010825217"
                            fields="name,email,picture,first_name"
                            callback={responseFacebook}
                            cssClass="button buttonLarge buttonBlock registerFacebook"
                            isMobile={false}
                            textButton="Register via Facebook"
                          />
                        </button>
                      </div>
                      <div className="orContainer orMargin">
                        <div className="orDivider"></div>
                        <div className="orCircle">
                          <div className="orText">OR</div>
                        </div>
                        <div className="orDivider"></div>
                      </div>
                    </div>
                  )} */}

                    <div id="registerId">
                      <h5 className="H5 regnFormHeading">Register via Email</h5>
                      {referralSubscriptionName && (
                        <h6
                          style={{
                            fontSize: "16px",
                            textTransform: "uppercase",
                          }}
                        >
                          Selected Plan:&nbsp;{referralSubscriptionName}
                        </h6>
                      )}
                      <form
                        className="regnformContainer"
                        noValidate
                        onSubmit={onSubmitHandler}
                      >
                        {isErrorMsg && <p className="_3nmo_">{msgError}</p>}
                        {isEmailExistMsg && (
                          <p className="_3nmo_">
                            {facebookEmail} already exist, Please&nbsp;&nbsp;
                            <button
                              onClick={onSignIn}
                              className="linkButton button buttonSmall"
                            >
                              <div className="buttonBg rounderbutton"></div>
                              <div className="buttonContent">Sign In</div>
                            </button>
                          </p>
                        )}
                        <div
                          className={"input" + firstname}
                          style={{ marginTop: "18px" }}
                        >
                          <input
                            className="inputText"
                            style={{
                              border: "none",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            name="firstname"
                            type="text"
                            maxLength="60"
                            value={values.firstname}
                            onChange={onChangeHandler}
                          />
                          <span className="inputLabel">{errors.firstname?errors.firstname:"Name"}</span>
                        </div>
                        <div
                          className={"input" + email}
                          style={{ marginTop: "22px" }}
                        >
                          <input
                            className="inputText"
                            style={{
                              border: "none",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            name="email"
                            type="email"
                            value={values.email}
                            onChange={onChangeHandler}
                          />
                          <span className="inputLabel">{errors.email?errors.email:"Email"}</span>
                          {!values.email && (
                            <span className="inputHint">
                              We never share this
                            </span>
                          )}
                        </div>

                        {/* ########### second email field ########### */}

                        <div
                          className={"input" + ConfirmMail}
                          style={{ marginTop: "20px" }}
                        >
                          <input
                            onPaste={(e) => {
                              e.preventDefault()
                              return false;
                            }}
                            id="confirm_mail"
                            onpaste={("paste", (e) => e.preventDefault())}
                            className="inputText"
                            style={{
                              border: "none",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            name="confirm_email"
                            type={"email"}
                            value={values.confirm_email}
                            onChange={onChangeHandler}
                          />

                          <span className="inputLabel">
                            {errors.confirm_email?errors.confirm_email:"Confirm Your Email"}
                          </span>
                          {!values.confirm_email && (
                            <span className="inputHint">
                              Confirm Your Email
                            </span>
                          )}
                        </div>

                        {/* ##################### Password field ##################### */}
                        <div
                          className={"input" + password}
                          style={{ marginTop: "20px" }}
                        >
                          <input
                            className="inputText"
                            style={{
                              border: "none",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            name="password"
                            type={passwordShown1 ? "text" : "password"}
                            value={values.password}
                            onChange={onChangeHandler}
                          />
                          {isEye1 && (
                            <i
                              className="eyeIcon"
                              onClick={togglePasswordVisiblity1}
                            >
                              {eye1}
                            </i>
                          )}

                          <span className="inputLabel">{errors.password?errors.password:"Password"}</span>
                          {!values.password && (
                            <span className="inputHint">
                              Pick something you can remember
                            </span>
                          )}
                        </div>

                        {haveCoupon ||
                        haveReferral ||
                        localStorage.getItem("haveReferral") == "true" ||
                        localStorage.getItem("haveReferral") == "zero" ? (
                          <div>
                            <div
                              className={"input" + firstname}
                              style={{ marginTop: "18px" }}
                            >
                              <input
                                className="inputText"
                                style={{
                                  border: "none",
                                  padding: "0px",
                                  marginTop: "10px",
                                }}
                                name="firstname"
                                type="text"
                                maxLength="60"
                                value={
                                  coupon
                                    ? coupon
                                    : localStorage.getItem("referralCode")
                                }
                                onChange={handleCoupon}
                                disabled={
                                  haveReferral ||
                                  localStorage.getItem("haveReferral") ==
                                    "true" ||
                                  localStorage.getItem("haveReferral") == "zero"
                                    ? true
                                    : false
                                }
                              />
                              {haveReferral ||
                              localStorage.getItem("haveReferral") == "true" ||
                              localStorage.getItem("haveReferral") ==
                                "zero" ? null : (
                                <button
                                  onClick={applyCoupons}
                                  style={{
                                    height: "40px",
                                    width: "100px",
                                    fontSize: "15px",
                                    marginLeft: "10px",
                                    borderRadius: "5px",
                                    backgroundColor: "#2c74ad",
                                    color: "#fff",
                                    position: "absolute",
                                    top: "15px",
                                    right: "10px",
                                    zIndex: "2",
                                  }}
                                  type="button"
                                >
                                  Apply
                                </button>
                              )}
                              <span className="inputLabel">
                                {haveReferral ||
                                localStorage.getItem("haveReferral") ==
                                  "true" ||
                                localStorage.getItem("haveReferral") == "zero"
                                  ? "Referral code"
                                  : "Have a Coupon Code?"}
                              </span>
                            </div>

                            {/* <div>
                                <input
                                  type="text"
                                  value={coupon}
                                  onChange={handleCoupon}
                                  style={{
                                    color: "white",
                                    width: "80%",
                                    height: "40px",
                                    background: "#000",
                                  }}
                                />

                                <button
                                  onClick={applyCoupons}
                                  style={{
                                    height: "40px",
                                    width: "100px",
                                    fontSize: "15px",
                                    marginLeft: "10px",
                                    borderRadius: "5px",
                                    backgroundColor: "orange",
                                  }}
                                  type="button"
                                >
                                  Apply
                                </button>
                              </div> */}
                            {success && (
                              <p style={{ color: "green" }}>
                                Coupon Code applied successfully
                              </p>
                            )}

                            {notExist && (
                              <p style={{ color: "red" }}>
                                Coupon Code does not exist
                              </p>
                            )}
                            {couponNotValid && (
                              <p style={{ color: "red" }}>
                                Coupon is not valid for this subscription
                              </p>
                            )}
                            {Invalid && (
                              <p style={{ color: "red" }}>
                                Entered coupon code has expired
                              </p>
                            )}
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              setHaveCoupon(true);
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "400",
                                marginTop: "20px",
                                cursor: "pointer",
                              }}
                            >
                              Have a coupon?
                            </p>
                          </div>
                        )}
                        


                        {isDesktop ? (
                          <div className="regnSubmitWrapper">
                            <p
                              style={{
                                marginTop: "30px",
                                paddingTop: "10px",
                                fontSize: "14px",
                                color: "#707c86",
                              }}
                            >
                              Already have an account?
                              <Link to={{ pathname: "/signin" }}>
                                <span className="linkButton">
                                  &nbsp; Sign In
                                </span>
                              </Link>
                            </p>
                            <button
                              className="button buttonLarge regnSubmit"
                              type="submit"
                            >
                              <div className="buttonBg rounderbutton"></div>
                              <div
                                className="buttonContent"
                                style={{ color: "#fff" }}
                              >
                                Register
                              </div>
                            </button>
                          </div>
                        ) : (
                          <div>
                            <div className="regnSubmitWrapper">
                              <button
                                className="button buttonLarge regnSubmit"
                                style={{ width: "100vw" }}
                                type="submit"
                              >
                                <div className="buttonBg rounderbutton"></div>
                                <div
                                  className="buttonContent"
                                  style={{ color: "#fff" }}
                                >
                                  Register
                                </div>
                              </button>
                            </div>
                            <div className="regnSubmitWrapper">
                              <p
                                style={{
                                  paddingLeft: "20px",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  color: "#707c86",
                                }}
                              >
                                Already have an account?
                                <Link to={{ pathname: "/signin" }}>
                                  <span className="linkButton">
                                    &nbsp; Sign In
                                  </span>
                                </Link>
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="regnAgreeContent">
                          <p>
                            By registering, you agree to Project Forty-Six
                            {/* <Link to="/termsandconditions"> */}
                            <div
                              className="linkButton"
                              onClick={() => {
                                functionOnclick("/termsandconditions");
                              }}
                              // onClick={() => {
                              //   window.open(
                              //     "https://watch.projectfortysix.com/terms/99078"
                              //   );
                              // }}
                            >
                              &nbsp;Terms of Use
                            </div>
                            {/* </Link> */}
                            &nbsp; and
                            {/* <Link to="/privacypolicy"> */}
                            <div
                              className="linkButton"
                              onClick={() => {
                                functionOnclick("/privacypolicy");
                              }}
                            >
                              &nbsp;Privacy Policy
                            </div>
                            {/* </Link> */}
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : isRegister && loading ? (
                  <div style={{ marginBottom: "20vh" }}>
                    <h6>Loading...</h6>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Register;
