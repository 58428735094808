import React, { useState, useEffect } from "react";
import Show from "./Show";
import { Link, useHistory } from "react-router-dom";
const CategoryContainer = (param) => {
  const history = useHistory();
  const [category, setCategory] = useState([]);
  useEffect(() => {
    setCategory(param.param);
  }, [param]);

  const historyPush = (type) => {
    if (type === "NEW_RELEASES") {
      history.push({
        pathname: "/home/recentlyadded",
      });
    }
    else if (type === "FREE_SHOWS"){
      history.push({
        pathname: "/home/categorylist",
        search: encodeURI(
          `category_id=${`free__videos`}&category_name=${category.category_name}`
        ),
      });
    }
     else {
      history.push({
        pathname: "/home/categorylist",
        search: encodeURI(
          `category_id=${category.category_id}&category_name=${category.category_name}`
        ),
      });
    }
  };
  return (
    <section className="categoryWrapper">
      <div className="_2vKa8"></div>
      <div className="container categoryHeadWrapper">
        {category.category_name && category.category_id && (
          <div className="categoryLinkWrapper">
            <div className="categoryHeading" style={{ cursor: "pointer" }}>
              <div
                className="_2hvCx"
                onClick={() => {
                  if (category.category_id != "continuewatching") {
                    historyPush(category.category_id);
                  }
                }}
              >
                <h2 className="_1mK3G" style={{ paddingBottom: "15px" }}>
                  {category.category_name}
                  
                </h2>
              </div>
            </div>
            {(category.type === "CONTINUE_WATCHING" || category.type === "LIVE") ? (
              null
            ) : 
            <div
                className="categoryDotsWrapper"
                style={{ cursor: "pointer", fontWeight: "900" }}
                onClick={() => {
                  historyPush(category.type);
                }}
              >
                
                Browse More
              </div>
              }
          </div>
        )}
        {category.shows && (
          <Show
            param={category.shows}
            update={param}
            categoryId={category.type}
          />
        )}
      </div>
    </section>
  );
};
export default CategoryContainer;
