import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ToastsContainer, ToastsStore } from "react-toasts";
import closepanel from "../../img/icon-closepanel.png";
import "./videoPlayer.css";

const LivePlayer = (history) => {

    const historys = useHistory();
    const { event_id, live_url, logo_thumb, schedule_time, show_name } = history.location.state.param
    const [videoLoading, setVideoLoading] = useState(false);
    const [videoPlayer, setVideoPlayer] = useState(
        <video
            id="content_video"
            className="video-js vjs-default-skin mainPlayer"
            controls
            preload="auto"
        >
            {" "}
            <source src="" type="video/mp4" />{" "}
        </video>
    );
    useEffect(() => {

        window.scrollTo(0, 0);
        localStorage.setItem("fromVideoplayer", "true");
        let videoElem = "live_video" + new Date().valueOf();
        setVideoPlayer(
            <video
                id={videoElem}
                className="video-js vjs-default-skin mainPlayer"
                controls
                preload="auto"
                autoPlay
            >
                <source src={live_url} type="application/x-mpegURL" />
            </video>
        );

        setTimeout(() => {
            window.playLivePlayer(
                null,
                videoElem
            );
        }, 2000);


        return () => {
            try {
                window.disposeLivePlayer();
            } catch (e) {
                console.log("component unmounted", e);
            }
        };
    }, []);

    const closeVideo = () => {
        historys.push({
            pathname: "/home",
        });
    };

    return (
        <div className="pageWrapper searchPageMain">
            <div className="topContainer">
                <div className="homepageWrapper menuCloseJS closeMenuWrapper">
                    <div className="entireBanner" style={{ zIndex: "2" }} id="live">
                        <div className="hpLiveBanner">
                            {videoLoading == true ? (
                                <div
                                    className="closingButton"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => closeVideo()}
                                >
                                    <img
                                        src={closepanel}
                                        style={{ width: "35px", padding: "3px", opacity: ".5" }}
                                        className="close-video-button"
                                    />
                                </div>
                            ) : null}
                            <div className="liveVideoWrapper">{videoPlayer}</div>
                        </div>
                    </div>
                </div>
                <ToastsContainer store={ToastsStore} />
            </div>
        </div>
    );
};
export default LivePlayer;
