import React from "react";
import "./smallLoading.css";
const SmallLoading = () => {
  return (
    <div className="smallLoading">
      <div className="loading-container">
          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    </div>
  );
};

export default SmallLoading;
