import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import './subscription.css'
import { service } from "../../network/service";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

const Subscription = (param, androidData, isAndroid) => {

  const isLoggedIn = service.getCookie("isLoggedIn");
  const history = useHistory();
  const [currentPlan, setCurrentPlan] = useState('Monthly')
  const [monthTab, setMonthTab] = useState('item active')
  const [yearTab, setYearTab] = useState('item')

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSelectPlan = (plan) => {
    localStorage.setItem("selectedSubId", plan.publisher_subscription_id);
    localStorage.setItem("selectedAmount", plan.price);
    localStorage.setItem("newFlow", "1");
    setTimeout(async () => {

      if (isLoggedIn == "true") {
        let subscriptionId = plan.publisher_subscription_id;
        let session;
        localStorage.removeItem("coupon_id");
        session = await service.stripeSession(
          subscriptionId
        );
        console.log("session", session);
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
      } else {
        history.push({
          pathname: "/register",
          state: {
            paymentData: plan,
            androidData: androidData,
            isAndroid: isAndroid,
            from: '/SubscriptionList'
          },
        });
      }

    }, 1000);
  };

  const subscriptionList = param && param.param && param.param.map((item, index) => {

    if (currentPlan === 'Monthly' && item.subscription_type_name === 'Monthly') {
      return <div className="item">
        <div className="wrapper">
          <div className="title-section">
            <h4>{item.subscription_name}</h4>
            <hr></hr>
          </div>
          <div className="price-section">
            <h5>{"$" + item.price}</h5><span>&nbsp;per monthly</span>
          </div>
          {
            item.features && item.features.map((feature) => {
              return <div className="info-section">
                <span>{feature}</span>
              </div>
            })
          }
          <div>
            <button onClick={() => {
              onSelectPlan(item)
            }}>choose plan</button>
          </div>
        </div>
      </div>

    } else if (currentPlan === 'Yearly' && item.subscription_type_name === 'Yearly') {
      return <div className="item">
        <div className="wrapper">
          <div className="title-section">
            <h4>{item.subscription_name}</h4>
            <hr></hr>
          </div>
          <div className="price-section">
            <h5>{"$" + item.price}</h5><span>&nbsp;per yearly</span>
          </div>
          {
            item.features && item.features.map((feature) => {
              return <div className="info-section">
                <span>{feature}</span>
              </div>
            })
          }
          <div>
            <button onClick={() => {
              onSelectPlan(item)
            }}>choose plan</button>

          </div>
        </div>
      </div>
    }
  })

  const changeCurrentPlan = (plan) => {
    setCurrentPlan(plan)
    if (plan == 'Monthly') {
      setMonthTab('item active')
      setYearTab('item')
    } else {
      setMonthTab('item')
      setYearTab('item active')
    }
  }

  return (
    <div className="membership-container">
      <div className="wrapper">
        <div>
          <h1 className="membership-label">memberships</h1>
        </div>
        <div className="tab-section">
          <div className="tab-wrapper">
            <button className={monthTab} onClick={() => {
              changeCurrentPlan('Monthly')
            }}>Monthly</button>
            <button className={yearTab} onClick={() => {
              changeCurrentPlan('Yearly')
            }}>Yearly</button>

          </div>

        </div>
        <div className="subscription-list">
          <div className="inner-wrapper">

            {subscriptionList}

          </div>
        </div>
      </div>
    </div>
  );
};
export default Subscription;
